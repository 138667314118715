@import '@/styles/common.scss';

.el-row {
  margin-bottom: 3px;
  last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-light {
  background: white;
}
.grid-content {
  border-radius: 4px;
  min-height: 40px;
  margin-top: 5px;
}
.el-form-item {
  display: inline-block !important;
}
